import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";
import { Address } from "@/store/modules/addresses";

export async function searchAddress(searchText: string, country = "ZAF") {
  if (!process.env.VUE_APP_GEO_API) throw Error("No Geo Api Spesified");
  return Axios.get(process.env.VUE_APP_GEO_API, {
    params: {
      country,
      app_id: process.env.VUE_APP_GEO_API_ID,
      app_code: process.env.VUE_APP_GEO_API_CODE,
      searchText,
    },
  });
}

export async function fetchAddresses(orgId: number) {
  return Axios.get(generateUrl(`organisations/${orgId}/locations`), {
    headers: await generateHeader(),
  });
}

export async function fetchAddress(addressId: number) {
  return Axios.get(generateUrl(`locations/${addressId}`), {
    headers: await generateHeader(),
  });
}

export async function deleteAddress(addressId: number) {
  return Axios.delete(generateUrl(`locations/${addressId}`), {
    headers: await generateHeader(),
  });
}

export async function createAddress(orgId: number, address: Address) {
  return Axios.post(
    generateUrl(`organisations/${orgId}/locations`),
    { ...address },
    {
      headers: await generateHeader(),
    }
  );
}
export async function updateAddress(addressId: number, address: Address) {
  return Axios.put(
    generateUrl(`locations/${addressId}`),
    { ...address },
    {
      headers: await generateHeader(),
    }
  );
}
