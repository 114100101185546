import { MutationTree } from "vuex";
import { ConnectionsState } from "@/store/connections/types";

export const mutations: MutationTree<ConnectionsState> = {
  setConnections(state, payload) {
    state.connections = payload;
    state.loading = false;
  },

  setConnectionsPending(state, payload) {
    state.connectionsPending = payload;
    state.loading = false;
  },

  setPossibleConnections(state, payload) {
    state.possibleConnections = payload;
    state.loading = false;
  },

  setConnectionDiscounts(state, payload) {
    state.connectionDiscounts = payload;
    state.loading = false;
  },

  setConnectionDiscountsAndSettings(state, payload) {
    state.connectionDiscountsAndSettings = payload;
    state.loading = false;
  },

  setConnectionSettings(state, payload) {
    // this holds buyer id and minimum amount
    state.connectionSettings = payload;
    state.loading = false;
  },

  setConnectionSearch(state, payload) {
    state.connectionsSearch = payload;
    state.loading = false;
  },

  loading(state, payload = true) {
    state.loading = payload;
  },

  /* Paging */
  paginate(state, payload) {
    state.pagination = payload;
  },

  setTotalItems(state, payload) {
    state.totalItems = payload;
  },

  setCart(state, payload) {
    state.cart = payload;
    state.loading = false;
  },

  setOrderFor(state, payload) {
    state.orderFor = payload;
  },
};
