import { Module } from "vuex";
import { getters } from "@/store/connections/getters";
import { actions } from "@/store/connections/actions";
import { mutations } from "@/store/connections/mutations";
import { ConnectionsState, Connection } from "@/store/connections/types";
import { RootState } from "../types";
import { pagingDefault } from "../utils";

export const state: ConnectionsState = {
  connections: [],
  connectionsPending: [],
  possibleConnections: [],
  connectionsSearch: [],
  connectionDiscounts: [],
  connectionDiscountsAndSettings: [],
  connectionSettings: [],
  loading: false,
  pagination: pagingDefault,
  totalItems: 0,
  cart: null,
  orderFor: {} as Connection,
};

const namespaced: boolean = true;

export const connections: Module<ConnectionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
