import { store } from "@/store";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";
import { Paging } from "@/store/utils";

export interface ConnectionsBody {
  page?: number;
  pagesize?: number;
  status?: ConnectionsStatus;
  showDisabled?: boolean;
  type?: number;
  organisationType?: string;
  search?: string;
}

export interface PossibleConnectionsBody {
  page?: number;
  pagesize?: number;
  collectionDistance: number;
  deliveryPlace?: string;
  connectionStatus?: string;
  excludePrimary?: boolean;
  excludeCollection?: boolean;
  excludeDelivery?: boolean;
  search?: string;
}

export interface DiscountProducts {
  connectionId: number;
  search?: string;
}

export type ConnectionsStatus =
  | "PENDING"
  | "CONNECTED"
  | "DECLINED"
  | undefined;

export class ConnectionsApi {
  static async fetchConnectionByOrgCode(code: string) {
    return Axios.get(generateUrl(`organisations/code/${code}`), {
      headers: await generateHeader(),
    });
  }

  static async fetchConnectionByOrgName(name: string) {
    return Axios.get(generateUrl(`organisations/search`), {
      params: { name: name },
      headers: await generateHeader(),
    });
  }

  static async createConnectionByOrgCode(
    code: string,
    fromOrganisationId: number,
    typeCode = "M"
  ) {
    return Axios.put(
      generateUrl("connections/code/" + code),
      { fromOrganisationId, typeCode },
      {
        headers: await generateHeader(),
      }
    );
  }

  static async fetchConnectionByConnectionId(id: number) {
    return Axios.get(generateUrl(`connections/${id}`), {
      headers: await generateHeader(),
    });
  }

  static async fetchCart(userId: string) {
    return Axios.get(generateUrl(`cart/user/${userId}`), {
      headers: await generateHeader(),
    });
  }

  static async addToCart(userId: string, item: any) {
    return Axios.put(generateUrl(`cart/user/${userId}`), item, {
      headers: await generateHeader(),
    });
  }

  static async removeFromCart(userId: string, itemId: any) {
    return Axios.delete(generateUrl(`cart/user/${userId}/item/${itemId}`), {
      headers: await generateHeader(),
    });
  }

  static async placeOrder(userId: string, orderPayload: object) {
    return Axios.post(
      generateUrl(`cart/user/${userId}/order`),
      { ...orderPayload },
      {
        headers: await generateHeader(),
      }
    );
  }

  static async updateConnectionSettingByConnectionId(
    connectionId: number,
    body: Array<Object>
  ) {
    return Axios.put(
      generateUrl(`connections/${connectionId}/settings`),
      body,
      {
        headers: await generateHeader(),
      }
    );
  }

  static async fetchConnectionSettingsByConnectionId(id: number) {
    return Axios.get(generateUrl(`connections/${id}/settings`), {
      headers: await generateHeader(),
    });
  }

  static async createConnection(
    toOrganisationId: number,
    fromOrganisationId: number,
    typeCode = "M"
  ) {
    return Axios.post(
      generateUrl("connections"),
      { toOrganisationId, fromOrganisationId, typeCode },
      {
        headers: await generateHeader(),
      }
    );
  }

  static async createNewConnection(
    organisationId: number,
    toOrganisationId: number
  ) {
    return Axios.put(
      generateUrl(`organisations/${organisationId}/sell`),
      { toOrganisationId },
      {
        headers: await generateHeader(),
      }
    );
  }

  static async fetchPossibleConnections(body: ConnectionsBody) {
    return Axios.get(
      generateUrl(
        `organisations/${store.state.shops.activeShop.id}/connections/possible/sell`
      ),
      {
        params: body,
        headers: await generateHeader(),
      }
    );
  }

  static async fetchCurrentConnections(body: ConnectionsBody) {
    return Axios.get(
      generateUrl(
        `organisations/${store.state.shops.activeShop.id}/connections`
      ),
      {
        params: body,
        headers: await generateHeader(),
      }
    );
  }

  static async fetchDiscountProducts(params: DiscountProducts, paging: Paging) {
    return Axios.get(
      generateUrl(`connections/${params.connectionId}/product/discount`),
      {
        params: {
          search: params.search,
          page: paging.page,
          pagesize: paging.itemsPerPage,
        },
        headers: await generateHeader(),
      }
    );
  }

  static async updateProductDiscount(
    connectionId: number,
    productId: number,
    amount: number
  ) {
    return Axios.put(
      generateUrl(`connections/${connectionId}/product/${productId}/discount`),
      { amount },
      {
        headers: await generateHeader(),
      }
    );
  }

  static async deleteConnection(connectionId: number) {
    return Axios.delete(generateUrl(`connections/${connectionId}`), {
      headers: await generateHeader(),
    });
  }

  static async enableConnection(connectionId: number) {
    return Axios.put(
      generateUrl(`connections/${connectionId}/enable`),
      {},
      {
        headers: await generateHeader(),
      }
    );
  }

  static async acceptConnection(connectionId: number) {
    return Axios.put(
      generateUrl(
        `connections/${connectionId}/organisation/${store.state.shops.activeShop.id}/accept`
      ),
      {},
      {
        headers: await generateHeader(),
      }
    );
  }

  static async declineConnection(connectionId: number) {
    return Axios.put(
      generateUrl(
        `connections/${connectionId}/organisation/${store.state.shops.activeShop.id}/decline`
      ),
      {},
      {
        headers: await generateHeader(),
      }
    );
  }
}
