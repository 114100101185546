import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ConnectionsState, ConnectionSetting } from "@/store/connections/types";
import {
  ConnectionsApi,
  ConnectionsBody,
  PossibleConnectionsBody,
  DiscountProducts,
} from "@/api/connections";
import { store } from "..";
import { fetchAddress, fetchAddresses } from "@/api/address";
import { OrdersApiService } from "@/api/orders";

export const actions: ActionTree<ConnectionsState, RootState> = {
  /* Possible Connections */
  async fetchPossibleConnections(
    { commit },
    payload: PossibleConnectionsBody = {
      collectionDistance: 10,
      connectionStatus: "NONE",
    }
  ) {
    try {
      commit("loading");
      const result = await ConnectionsApi.fetchPossibleConnections(payload);
      commit("setPossibleConnections", result.data.results);
      commit("setTotalItems", result.data.count);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchConnections({ commit, state }, payload: ConnectionsBody) {
    try {
      commit("loading");
      const result = await ConnectionsApi.fetchCurrentConnections({
        ...payload,
        page: state.pagination.page,
        pagesize: state.pagination.itemsPerPage,
      });

      // strip out DECLINED connections
      let setConnections = result.data.results.filter(
        (el: any) => el.status !== "DECLINED"
      );

      commit("setConnections", setConnections);
      commit("setTotalItems", setConnections.length);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchConnectionsPending({ commit, state }, payload: ConnectionsBody) {
    try {
      commit("loading");
      const result = await ConnectionsApi.fetchCurrentConnections({
        ...payload,
        page: state.pagination.page,
        pagesize: state.pagination.itemsPerPage,
      });
      commit("setConnectionsPending", result.data.results);
      commit("setTotalItems", result.data.count);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchDiscountProducts(
    { commit, state, dispatch },
    params: DiscountProducts
  ) {
    try {
      commit("loading");
      const result = await ConnectionsApi.fetchDiscountProducts(
        params,
        state.pagination
      );
      commit("setConnectionDiscounts", result.data.results || []);
      commit("setTotalItems", result.data.count);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async updateProductDiscount(
    { commit },
    data: { connectionId: number; productId: number; amount: number }
  ) {
    try {
      commit("loading");
      await ConnectionsApi.updateProductDiscount(
        data.connectionId,
        data.productId,
        data.amount
      );
      commit("notifications/success", "Discount has been updated", {
        root: true,
      });
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async deleteConnections({ commit, dispatch }, connectionId) {
    try {
      commit("loading");
      await ConnectionsApi.deleteConnection(connectionId);
      dispatch("notifications/success", "Connection has been disabled!", {
        root: true,
      });
      dispatch("fetchConnections");
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },
  async fetchConnectionByOrgCode({ commit, dispatch }, connectionObj) {
    try {
      commit("loading");
      if (connectionObj.code != "") {
        let results = await ConnectionsApi.fetchConnectionByOrgCode(
          connectionObj.code
        );
        commit("loading", false);
        // commit data here
        commit("setConnectionSearch", results.data);
      } else {
        commit("setConnectionSearch", {});
      }

      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        "Not Found. Please check your S2S code.",
        { root: true }
      );
      commit("setConnectionSearch", {
        id: 0,
        name: "Not Found. Please check your S2S code.",
      });
      commit("loading", false);
      return false;
    }
  },
  async fetchConnectionByOrgName({ commit, dispatch }, connectionObj) {
    try {
      commit("loading");
      if (connectionObj.name != "") {
        let response = await ConnectionsApi.fetchConnectionByOrgName(
          connectionObj.name
        );
        commit("loading", false);
        // commit data here
        commit("setConnectionSearch", response.data.results);
      } else {
        commit("setConnectionSearch", {});
      }

      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        "Not Found. Please check your S2S Name.",
        { root: true }
      );
      commit("setConnectionSearch", {
        id: 0,
        name: "Not Found. Please check your S2S Name.",
      });
      commit("loading", false);
      return false;
    }
  },

  async fetchConnectionSettingsByConnectionId(
    { commit },
    connectionId: number
  ) {
    try {
      commit("loading");
      if (connectionId != null) {
        let results =
          await ConnectionsApi.fetchConnectionSettingsByConnectionId(
            connectionId
          );
        commit("loading", false);
        // commit data here
        commit("setConnectionSettings", results.data.results);
      } else {
        commit("setConnectionSettings", {});
      }

      return true;
    } catch {
      commit("setConnectionDiscountsAndSettings", {});
      commit("loading", false);
      return false;
    }
  },

  async fetchConnectionByConnectionId({ commit }, connectionId: number) {
    try {
      commit("loading");
      if (connectionId != null) {
        let results = await ConnectionsApi.fetchConnectionByConnectionId(
          connectionId
        );
        commit("loading", false);
        // commit data here
        commit("setConnectionDiscountsAndSettings", results.data);
      } else {
        commit("setConnectionDiscountsAndSettings", {
          id: "NAN",
        });
      }

      return true;
    } catch {
      commit("setConnectionDiscountsAndSettings", {});
      commit("loading", false);
      return false;
    }
  },

  // used to display "review order" for selected org
  async fetchShopForOrg({ commit, rootState }, connectionId: number) {
    const connectionData = await ConnectionsApi.fetchConnectionByConnectionId(
      connectionId
    );

    let connections = null;
    if (
      connectionData.data.fromOrganisation.id === rootState.shops.activeShop.id
    ) {
      connections = connectionData.data.toOrganisation;
    } else {
      connections = connectionData.data.fromOrganisation;
    }

    let addressRaw = await fetchAddresses(connections.id);
    let addresses = addressRaw.data.results;

    let connection = { addresses, ...connections };
    commit("setOrderFor", connection);
  },

  async fetchCart({ commit, rootState }) {
    try {
      commit("loading", true);
      let results = await ConnectionsApi.fetchCart(
        rootState.auth.loggedInUser!.sub
      );
      commit("setCart", results.data);
    } catch {
      commit("loading", false);
    }
  },

  async addToCart({ commit, dispatch, rootState }, item: any) {
    try {
      commit("loading", true);
      let product = {
        productId: item.productId,
        optionIds: item.optionIds,
        quantity: item.quantity,
      };
      let results = await ConnectionsApi.addToCart(
        rootState.auth.loggedInUser!.sub,
        product
      );
      commit("setCart", results.data);
      dispatch(
        "notifications/success",
        `Item ${item.productName} - ${item.name} added to cart!`,
        { root: true }
      );
    } catch {
      commit("loading", false);
    }
  },

  async updateCart({ commit, dispatch, rootState }, item: any) {
    try {
      let product = {
        productId: item.productId,
        optionIds: item.optionIds,
        quantity: item.quantity,
      };

      // first delete item from cart
      const remove = dispatch("removeFromCart", item.itemHash);
      if (remove) {
        commit("loading", true);
        let results = await ConnectionsApi.addToCart(
          rootState.auth.loggedInUser!.sub,
          product
        );
        commit("setCart", results.data);
        dispatch("notifications/success", `Cart updated!`, { root: true });
      }
    } catch {
      commit("loading", false);
    }
  },

  async removeFromCart({ commit, dispatch, rootState }, itemHash: string) {
    const response = await ConnectionsApi.removeFromCart(
      rootState.auth.loggedInUser!.sub,
      itemHash
    );
    if (response.status === 200) {
      commit("setCart", response.data);
      dispatch("notifications/success", `Item removed from Cart!`, {
        root: true,
      });
    }
  },

  async placeOrder({ commit, dispatch, rootState }, orderPayload: object) {
    try {
      commit("loading", true);
      const response = await ConnectionsApi.placeOrder(
        rootState.auth.loggedInUser!.sub,
        orderPayload
      );
      let regex = /(http|ftp|https):\/\/[a-z-.]*(\/resource\/orders\/)/g;
      let location = response.headers.location;
      let orderId = location.replace(regex, "");

      await OrdersApiService.changeOrderStatus(orderId, "pending");
      if (response.status === 201) {
        commit("loading", false);
        dispatch("fetchCart");
        dispatch("notifications/success", `Order placed!`, { root: true });
      }
    } catch (error) {
      commit("loading", false);
      dispatch("notifications/error", `Order could not be placed!, ${error}`, {
        root: true,
      });
    }
  },

  async updateConnectionSettingByConnectionId(
    { commit, dispatch },
    data: ConnectionSetting
  ) {
    try {
      commit("loading");
      await ConnectionsApi.updateConnectionSettingByConnectionId(
        data.connectionId,
        data.body
      );
      dispatch(
        "notifications/success",
        "Connection settings have been updated!",
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async createConnectionByOrgCode({ commit, dispatch }, connectionObj) {
    try {
      commit("loading");
      await ConnectionsApi.createConnectionByOrgCode(
        connectionObj.code,
        store.state.shops.activeShop.id,
        connectionObj.typeCode
      );
      dispatch("notifications/success", "Customer has been added!", {
        root: true,
      });
      commit("loading", false);
      return true;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
      return false;
    }
  },

  async createNewConnection({ commit, dispatch }, toOrganisationId: number) {
    try {
      commit("loading");
      await ConnectionsApi.createNewConnection(
        store.state.shops.activeShop.id,
        toOrganisationId
      );
      dispatch("notifications/success", "Customer has been added!", {
        root: true,
      });
      commit("loading", false);
      return true;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
      return false;
    }
  },

  async createConnection({ commit, dispatch }, connectionObj) {
    try {
      commit("loading");
      await ConnectionsApi.createConnection(
        connectionObj.toOrganisationId,
        store.state.shops.activeShop.id,
        connectionObj.typeCode
      );
      dispatch("notifications/success", "Customer has been added!", {
        root: true,
      });
      commit("loading", false);
      return true;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
      return false;
    }
  },

  async enableConnection({ commit, dispatch }, connectionId) {
    try {
      commit("loading");
      await ConnectionsApi.enableConnection(connectionId);
      commit("notifications/success", "Customer enabled!", { root: true });
      dispatch("fetchConnections");
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async acceptConnection({ commit }, connectionId) {
    try {
      commit("loading");
      await ConnectionsApi.acceptConnection(connectionId);
      commit("notifications/success", "Customer Approved!", { root: true });
      return true;
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async declineConnection({ commit }, connectionId) {
    try {
      commit("loading");
      await ConnectionsApi.declineConnection(connectionId);
      commit("notifications/success", "Customer Declined!", { root: true });
      return true;
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async refresh({ dispatch, state }) {
    dispatch("fetchPossibleConnections");
  },

  paginate({ commit }, pagination) {
    commit("paginate", pagination);
  },
};
