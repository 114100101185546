import { GetterTree } from "vuex";
import {
  ConnectionsState,
  ConnectionBuyerSetting,
} from "@/store/connections/types";
import { RootState } from "../types";
import { connections } from ".";

export const getters: GetterTree<ConnectionsState, RootState> = {
  // Build the correct object for us to read for the connections view screen
  myCustomers(state, getters, rootState) {
    const getOrgTypes = (types: any[]) => {
      const typeName = [];
      for (let type of types) {
        typeName.push(type.name);
      }
      return typeName.join(", ");
    };

    let customersObj = [];
    for (let conn of state.connections) {
      let notMyOrg!: "fromOrganisation" | "toOrganisation";

      if (conn.toOrganisation.id === rootState.shops.activeShop.id)
        notMyOrg = "fromOrganisation";
      else if (conn.fromOrganisation.id === rootState.shops.activeShop.id)
        notMyOrg = "toOrganisation";
      if (!conn[notMyOrg]) continue;
      const profile =
        conn[notMyOrg].profile && conn[notMyOrg].profile.contactPerson
          ? conn[notMyOrg].profile.contactPerson.profile
          : null;
      if (conn.status === "CONNECTED") {
        const myCustObj = {
          connectionId: conn.id,
          customerCode: conn[notMyOrg].code,
          customerName: conn[notMyOrg].name,
          contactName: profile
            ? `${profile.firstName} ${profile.surname}`
            : "-",
          status: conn.status,
          types: getOrgTypes(conn[notMyOrg].types),
          id: conn.id,
          enabled: conn.enabled,
          city: conn[notMyOrg].address
            ? conn[notMyOrg].address!.city
              ? conn[notMyOrg].address!.city
              : "-"
            : "-",
          province: conn[notMyOrg].address
            ? conn[notMyOrg].address!.province
              ? conn[notMyOrg].address!.province
              : "-"
            : "-",
        };

        customersObj.push(myCustObj);
      }
    }

    return customersObj;
  },

  pendingCustomers(state, getters, rootState) {
    const getOrgTypes = (types: any[]) => {
      const typeName = [];
      for (let type of types) {
        typeName.push(type.name);
      }
      return typeName.join(", ");
    };

    let customersObj = [];
    let customerInfo = {};
    for (let conn of state.connectionsPending) {
      if (conn.requestedBy != null) {
        let connection = null;
        if (conn.requestedBy.id === rootState.shops.activeShop.id) {
          if (conn.requestedBy.id === conn.toOrganisation.id) {
            connection = conn.fromOrganisation;
          } else {
            connection = conn.toOrganisation;
          }
        } else {
          if (conn.requestedBy.id === conn.toOrganisation.id) {
            connection = conn.toOrganisation;
          } else {
            connection = conn.fromOrganisation;
          }
        }

        const profile =
          connection.profile && connection.profile.contactPerson
            ? connection.profile.contactPerson.profile
            : null;
        customerInfo = {
          direction:
            conn.requestedBy.id === rootState.shops.activeShop.id
              ? "sell"
              : "buy",
          customerCode: connection.code,
          customerName: connection.name,
          contactName: profile
            ? `${profile.firstName} ${profile.surname}`
            : "-",
          contactNumber:
            profile && profile.contactNumber ? `${profile.contactNumber}` : "-",
          contactAddress:
            connection.address &&
            connection.address!.city != null &&
            connection.address!.province != null
              ? connection.address!.city + ", " + connection.address!.province
              : "-",
          status: conn.status,
          types: getOrgTypes(connection.types),
          id: conn.id,
          requestedBy: conn.requestedBy,
          dateCreated: conn.dateCreated,
        };

        const myCustObj = customerInfo;
        customersObj.push(myCustObj);
      }
    }

    return customersObj;
  },

  // Build the correct object for us to read for the add connection view screen
  possibleCustomers(state) {
    const possibleCustomers = state.possibleConnections;
    let customersObj = [];

    const getOrgTypes = (types: any[]) => {
      const typeName = [];
      for (let type of types) {
        typeName.push(type.name);
      }
      return typeName.join(", ");
    };

    for (let conn of possibleCustomers) {
      const profile =
        conn.profile && conn.profile.contactPerson
          ? conn.profile.contactPerson.profile
          : null;

      let address = {
        province: "",
        city: "",
        suburb: "",
      };
      if (conn.primaryPlaces && conn.primaryPlaces.length) {
        address = {
          province: conn.primaryPlaces[0]
            ? conn.primaryPlaces[0].province
            : "-",
          city: conn.primaryPlaces[0] ? conn.primaryPlaces[0].city : "-",
          suburb: conn.primaryPlaces[0] ? conn.primaryPlaces[0].suburb : "-",
        };
      } else if (conn.collectionPlaces && conn.collectionPlaces.length) {
        address = {
          province: conn.collectionPlaces[0]
            ? conn.collectionPlaces[0].province
            : "-",
          city: conn.collectionPlaces[0] ? conn.collectionPlaces[0].city : "-",
          suburb: conn.collectionPlaces[0]
            ? conn.collectionPlaces[0].suburb
            : "-",
        };
      } else {
        address = {
          province: conn.deliveryPlaces[0]
            ? conn.deliveryPlaces[0].province
            : "-",
          city: conn.deliveryPlaces[0] ? conn.deliveryPlaces[0].city : "-",
          suburb: conn.deliveryPlaces[0] ? conn.deliveryPlaces[0].suburb : "-",
        };
      }

      const myCustObj = {
        customerCode: conn.code,
        customerName: conn.name,
        contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
        types: getOrgTypes(conn.types),
        id: conn.id,
        province: address.province,
        city: address.city,
        suburb: address.suburb,
        deliveryDistance: conn.deliveryDistance,
        collectionDistance: conn.collectionDistance,
        primaryDistance: conn.primaryDistance,
        connection: conn.connection,
      };

      customersObj.push(myCustObj);
    }
    return customersObj;
  },

  possibleProvinceCities(state) {
    const possibleCustomers = state.possibleConnections;

    let locationData: Array<{
      province: string;
      city: string;
      suburb: string;
    }> = [];

    for (let conn of possibleCustomers) {
      if (conn.primaryPlaces && conn.primaryPlaces.length) {
        const province = conn.primaryPlaces[0].province;
        const city = conn.primaryPlaces[0].city;
        const suburb = conn.primaryPlaces[0].suburb;
        locationData.push({ province: province, city: city, suburb: suburb });
      } else if (conn.collectionPlaces && conn.collectionPlaces.length) {
        const province = conn.collectionPlaces[0].province;
        const city = conn.collectionPlaces[0].city;
        const suburb = conn.collectionPlaces[0].suburb;
        locationData.push({ province: province, city: city, suburb: suburb });
      } else if (conn.deliveryPlaces && conn.deliveryPlaces.length) {
        const province = conn.deliveryPlaces[0].province;
        const city = conn.deliveryPlaces[0].city;
        const suburb = conn.deliveryPlaces[0].suburb;
        locationData.push({ province: province, city: city, suburb: suburb });
      }
    }
    return locationData;
  },

  connectionsSearch(state, getters, rootState) {
    const search = state.connectionsSearch;
    return search;
  },

  connectionSettings(state, getters, rootState) {
    const settings = state.connectionDiscountsAndSettings;

    let connectionSettings = {};
    let types: any = [];

    if (settings.fromOrganisation == null) return; // stops console errors as renders before data

    if (settings.fromOrganisation.id === rootState.shops.activeShop.id) {
      settings.toOrganisation.types.forEach((el: any) => {
        types.push(el.name);
      });

      connectionSettings = {
        id: settings.toOrganisation.id,
        code: settings.toOrganisation.code,
        name: settings.toOrganisation.name,
        types: types.join(", "),
      };
    } else {
      settings.fromOrganisation.types.forEach((el: any) => {
        types.push(el.name);
      });

      connectionSettings = {
        id: settings.fromOrganisation.id,
        code: settings.fromOrganisation.code,
        name: settings.fromOrganisation.name,
        types: types.join(", "),
      };
    }

    return connectionSettings;
  },

  connectionBuyerSettings(state) {
    let settings: Array<ConnectionBuyerSetting> = [];
    state.connectionSettings.forEach((setting: any) => {
      const settingObj = {
        name: setting.key.name,
        id: setting.key.id,
        value: setting.value == null ? null : setting.value,
      };
      settings[setting.key.name] = settingObj;
    });
    return settings;
  },

  discountProducts(state) {
    const products = [];
    for (let connection of state.connectionDiscounts) {
      connection.productItem.discount = connection.amount;
      products.push(connection.productItem);
    }
    return products;
  },
};
